import React from 'react';
import s from '@components/Navigations/NavigationDashboard/NavigationDashboard.module.scss';
import { Body4, Label3 } from '@pixiepkg/react';
import { BellOutline, CalendarOutline, PixieSolid, SettingsOutline } from '@pixiepkg/icon';
import classNames from 'classnames';
import { Calendar1Outline, GroupUserOutline, Wallet2Outline } from '@components/Icon';
import { useRouter } from 'next/router';
import { DashboardRoute } from './types';
import Link from 'next/link';
import { useAtom } from 'jotai';
import { sessionAtom } from '@shared/stores/session.store';
import { useTranslation } from 'next-i18next';
import { useSendEvent } from '@shared/hooks/useSendEvent';

interface DashboardLinkProps {
	path: string;
	active?: boolean;
	children: React.ReactNode;
	onClick?: () => void;
}

const DashboardLink = ({ active, children, path, onClick }: DashboardLinkProps) => {
	const pathRouter = useRouter().pathname;

	return (
		<Link href={path} passHref legacyBehavior>
			<div
				className={classNames(
					s.linkWrapper,
					active && s.linkActive,
					path === pathRouter && s.linkActive
				)}
				onClick={onClick}
			>
				<div className={s.link}>{children}</div>
			</div>
		</Link>
	);
};

export const NavigationDashboard = () => {
	const { t } = useTranslation('common');
	const [session] = useAtom(sessionAtom);
	const { sendEventGAAndBackend } = useSendEvent();

	return (
		<div className={s.container}>
			<nav className={s.nav}>
				<Link href={'/'} legacyBehavior>
					<a className={s.logo}>
						<PixieSolid size={72} />
					</a>
				</Link>
				<DashboardLink
					path={DashboardRoute.P2PS}
					onClick={() => {
						sendEventGAAndBackend('click_sidebar_p2p');
					}}
				>
					<GroupUserOutline size={20} />
					<Label3>{t('nav.p2p')}</Label3>
				</DashboardLink>
				<DashboardLink
					path={DashboardRoute.WEBINARS}
					onClick={() => {
						sendEventGAAndBackend('click_sidebar_webinar');
					}}
				>
					<CalendarOutline size={20} />
					<Label3>{t('nav.webinar')}</Label3>
				</DashboardLink>

				<DashboardLink
					path={DashboardRoute.NOTIFICATIONS}
					onClick={() => sendEventGAAndBackend('click_sidebar_notification')}
				>
					<BellOutline size={20} />
					<Label3>{t('nav.notification')}</Label3>
				</DashboardLink>
				{session.user?.expert && (
					<>
						<DashboardLink
							path={DashboardRoute.SCHEDULE_SETTINGS}
							onClick={() => sendEventGAAndBackend('click_sidebar_schedule')}
						>
							<Calendar1Outline size={20} />
							<Label3>{t('nav.scheduleSettings')}</Label3>
						</DashboardLink>
					</>
				)}
				<DashboardLink path={DashboardRoute.PAY_OUT}>
					<Wallet2Outline size={20} />
					<Label3>{t('nav.payout')}</Label3>
				</DashboardLink>
				<div className={s.seperator} />
				<DashboardLink
					path={DashboardRoute.ACCOUNT_SETTINGS}
					onClick={() => sendEventGAAndBackend('click_sidebar_account')}
				>
					<SettingsOutline size={20} />
					<Label3>{t('nav.accountSettings')}</Label3>
				</DashboardLink>
			</nav>
			<div className={s.footer}>
				<Body4 medium>© 2021 Pixie</Body4>
			</div>
		</div>
	);
};
