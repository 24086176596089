import React, { Fragment, useMemo } from 'react';
import { GlobalOutlineWhite, GlobeOutlined } from '@components/Icon';
import { Menu, Transition } from '@headlessui/react';
import { CheckmarkOutline } from '@pixiepkg/icon';
import { locales } from '@shared/constant/locales';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import classNames from 'classnames';
interface IProps {
	isDark: boolean;
}
export const ChangeLanguages: React.FC<IProps> = ({ isDark }) => {
	const router = useRouter();
	const [cookies, setCookie] = useCookies(['NEXT_LOCALE']);

	const toggleLanguage = (locale: string) => {
		let newURL = router.asPath;
		if (locale === 'en') {
			newURL = router.asPath.replaceAll(`/${cookies.NEXT_LOCALE}`, '');
		} else if (cookies.NEXT_LOCALE === 'en') {
			newURL = `/${locale}${router.asPath}`;
		} else {
			newURL = router.asPath.replaceAll(cookies.NEXT_LOCALE, locale);
		}

		setCookie('NEXT_LOCALE', locale, { path: '/' });
		router.replace(newURL, undefined, { locale: locale });
	};

	const renderLanguageDropDownLabel = (locale?: string, isDark?: boolean) => {
		if (!locale && isDark) {
			return <GlobalOutlineWhite size={20} />;
		}
		if (!locale) {
			return <GlobeOutlined size={20} />;
		}

		if (locale === 'vi') {
			return 'Tiếng Việt';
		}

		if (locale === 'en') {
			return 'English';
		}

		return 'Unknown';
	};

	const renderLanguageButtonLabel = (locale?: string, isDark?: boolean) => {
		if (!locale && isDark) {
			return <GlobalOutlineWhite size={20} />;
		}
		if (!locale) {
			return <GlobeOutlined size={20} />;
		}

		return <>{!locale || locale === 'en' ? 'EN' : locale.toString().toUpperCase()}</>;
	};

	const IsLanguageActive = (locale?: string) =>
		useMemo(() => {
			const cookieLocale = cookies.NEXT_LOCALE;
			if (!locale || !cookieLocale) {
				return false;
			}

			return cookieLocale === locale;
		}, [locale]);

	return (
		<div className="mr-5">
			<Menu as="div" className="relative inline-block text-left">
				<div>
					<Menu.Button
						className={classNames(
							'rounded-lg p-2 px-3 pt-3 ',
							isDark
								? 'text-white hover:bg-black'
								: "active:bg-gray-200' hover:bg-gray-100"
						)}
					>
						{renderLanguageButtonLabel(cookies.NEXT_LOCALE, isDark)}
					</Menu.Button>
				</div>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute right-0 top-12 z-20 overflow-hidden rounded-lg border border-gray-100 bg-white shadow-level-4">
						{locales.map((locale) => {
							return (
								<Menu.Item key={locale}>
									{({ active }) => (
										<button
											className={classNames(
												active && 'bg-gray-200',
												'group flex h-12 w-40 items-center px-4 py-2 text-sm',
												IsLanguageActive(locale) && 'text-brand-500'
											)}
											onClick={() => toggleLanguage(locale)}
										>
											<div className="flex w-full justify-between">
												{renderLanguageDropDownLabel(locale, isDark)}
												{IsLanguageActive(locale) && (
													<CheckmarkOutline color="#616BFF" size={20} />
												)}
											</div>
										</button>
									)}
								</Menu.Item>
							);
						})}
					</Menu.Items>
				</Transition>
			</Menu>
		</div>
	);
};
