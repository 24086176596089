import React from 'react';

interface Props {
	color?: string;
	size?: number;
	children?: React.ReactNode;
}

export const IconWrapper = ({ color, size, children }: Props) => {
	return (
		<div
			style={{
				backgroundColor: color ?? 'white',
				height: size ?? 36,
				width: size ?? 36,
				color: 'white'
			}}
			className="flex items-center justify-center rounded-full"
		>
			{children}
		</div>
	);
};
