import {
	AstronautColored,
	Calendar1Outline,
	GlobeOutlined,
	HambergerOutline,
	HamburgerWhite,
	PixieSolid2,
	PixieSolidDark,
	Wallet2Outline
} from '@components/Icon';
import { navMainItems } from '@components/Navigations/NavigationBarMobile/const';
import { Button, H5, KindButton, Label1, Label3, Textbox } from '@pixiepkg/react';
import {
	BellOutline,
	CalendarNumberOutline,
	CalendarOutline,
	CheckmarkOutline,
	CloseOutline,
	ExitLogoutOutline,
	SearchOutline,
	UserOutline
} from '@pixiepkg/icon';
import { sessionAtom } from '@shared/stores/session.store';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import React, { useState } from 'react';
import Image from 'next/legacy/image';
import s from '@components/Navigations/NavigationBarMobile/styles/nav.module.scss';
import { loginModal } from '@shared/stores/loginModal.store';
import { locales } from '@shared/constant/locales';
import { api } from '@api';
import { toast } from 'react-toastify';
import { DashboardRoute } from '../NavigationDashboard/types';
import { useTranslation } from 'next-i18next';
import { notificationsAtom } from '@shared/stores/notifications.store';
import { useSendEvent } from '@shared/hooks/useSendEvent';
import Link from 'next/link';
import { SCREEN_SIZES, useWindowSize } from '@shared/hooks/useWindownSize';

export const NavigationBarMobile: React.FC<{ isDark: boolean }> = ({ isDark = false }) => {
	const { t } = useTranslation('common');
	const router = useRouter();
	const [openMainMenu, setOpenMainMenu] = useState(false);
	const [openUserMenu, setOpenUserMenu] = useState(false);
	const [, setOpenLoginModal] = useAtom(loginModal);
	const [session, setSession] = useAtom(sessionAtom);
	const [cookies, setCookie] = useCookies(['NEXT_LOCALE']);
	const { width } = useWindowSize();
	const [notis] = useAtom(notificationsAtom);
	const newNotis = notis.filter((noti) => noti.status === 'new');
	const [searchStr, setSearchStr] = useState((router.query?.keyword as string) || '');
	const { sendEventGAAndBackend } = useSendEvent();

	const Label = width > SCREEN_SIZES['2xs'] ? Label1 : Label3;
	const SizeIconItem = width > SCREEN_SIZES['2xs'] ? 22 : 16;

	const toggleLanguage = (locale: string) => {
		let newURL = router.asPath;
		if (locale === 'en') {
			newURL = router.asPath.replaceAll(`/${cookies.NEXT_LOCALE}`, '');
		} else if (cookies.NEXT_LOCALE === 'en') {
			newURL = `/${locale}${router.asPath}`;
		} else {
			newURL = router.asPath.replaceAll(cookies.NEXT_LOCALE, locale);
		}

		setCookie('NEXT_LOCALE', locale, { path: '/' });
		router.replace(newURL, undefined, { locale: locale });
	};

	const onSearch = (str: string) => {
		router.push({
			pathname: '/experts',
			query: { ...router.query, keyword: str }
		});
	};

	const logOut = () => {
		sendEventGAAndBackend('click_header_logout');
		localStorage.removeItem('ACCESS_TOKEN');
		localStorage.removeItem('PROFILE_UUID');

		setSession({ isFetched: true, isSignedIn: false });
		api.removeAuthHeader();
		toast.success('Log out successful!');
	};

	return (
		<>
			<nav
				className={classnames(
					s.nav,
					s.fixed,
					'relative',
					isDark ? 'bg-[#1C1E23]' : 'bg-white'
				)}
			>
				{/* Mobile: Hamberger */}
				<div className="flex items-center">
					<Button
						kind={KindButton.GHOST}
						style={{ color: '#333C44' }}
						className={s.hamberger}
						onClick={() => {
							if (!openMainMenu) setOpenUserMenu(false);
							setOpenMainMenu(!openMainMenu);
						}}
					>
						{!openMainMenu ? (
							isDark ? (
								<HamburgerWhite size={24} />
							) : (
								<HambergerOutline size={24} />
							)
						) : (
							<CloseOutline size={24} color={isDark ? '#fff' : ''} />
						)}
					</Button>

					{/* Logo pixie */}
					<Link legacyBehavior href={'/'}>
						<a
							className="ml-14 mt-2"
							style={{ left: '50%', transform: 'translate(-50%, 0)' }}
						>
							{isDark ? <PixieSolidDark size={80} /> : <PixieSolid2 size={80} />}
						</a>
					</Link>
				</div>

				<div className="-ml-8 mr-2 w-80">
					<Textbox
						onClick={() => {
							sendEventGAAndBackend('click_home_search_bar');
						}}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								onSearch(searchStr);
								sendEventGAAndBackend('click_home_search_now', {
									search_content: searchStr
								});
							}
						}}
						value={searchStr}
						onChange={(e) => setSearchStr(e.currentTarget.value)}
						className=""
						iconLeft={<SearchOutline />}
						id="p-search-input"
					/>
				</div>

				<div className="flex">
					{session.isSignedIn && (
						<Button
							kind={KindButton.GHOST}
							onClick={() => router.push(DashboardRoute.NOTIFICATIONS)}
							className="mr-2"
						>
							<>
								<BellOutline size={20} color={isDark ? '#fff' : ''} />
								{newNotis.length > 0 && (
									<div className="absolute right-4 h-2 w-2 rounded-full bg-red" />
								)}
							</>
						</Button>
					)}

					<button
						onClick={() => {
							if (!session.isFetched || !session.isSignedIn) {
								setOpenMainMenu(false);
								setOpenLoginModal({
									isOpen: true,
									from: 'login_button'
								});
							} else {
								if (!openUserMenu) setOpenMainMenu(false);
								setOpenUserMenu(!openUserMenu);
								router.push(DashboardRoute.ACCOUNT_SETTINGS);
							}
						}}
						data-for={'app-tool-tip-avatar'}
						data-tip
						className={[s.mobileLogin, 'relative'].join(' ')}
					>
						{!session.user?.avatar && <AstronautColored size={40} />}
					</button>
				</div>
			</nav>
			{/* Menu for Mobile */}
			<div className={s.menuMobile}>
				{/* Main Menu */}
				<div
					className={classnames(s.menuMainContainer, openMainMenu && s.openMenuList)}
					onBlur={() => setOpenMainMenu(false)}
				>
					<div className={s.menuList}>
						{navMainItems.map((item, index) => (
							<div
								key={`item-main-menu-${index}`}
								className={classnames(s.menuListItem, item.active && s.activeItem)}
								onClick={() => {
									if (item.eventName) sendEventGAAndBackend(item.eventName);
									window.location.href = item.href;
								}}
							>
								<H5>
									<span className={s.content}>{item.name}</span>
								</H5>
							</div>
						))}

						<div className={[s.lineX, 'mb-4 mt-2'].join(' ')} />

						{/* Profile */}
						{session.user && (
							<>
								<div className="mb-3.5 ml-4 flex items-center">
									{session.user?.avatar ? (
										<Image
											width={width > SCREEN_SIZES['2xs'] ? 42 : 32}
											height={width > SCREEN_SIZES['2xs'] ? 42 : 32}
											alt="avatar"
											src={
												session.user?.avatar ||
												'https://dummyimage.com/40x40'
											}
											className="relative rounded-full"
										/>
									) : (
										<AstronautColored
											size={width > SCREEN_SIZES['2xs'] ? 42 : 32}
										/>
									)}
									<Label className="ml-3">{`${session.user?.firstName} ${session.user?.lastName}`}</Label>
								</div>
								<div className="mb-4 flex flex-col gap-y-2">
									<div
										className={classnames(
											s.item,
											router.pathname == DashboardRoute.P2PS && s.activeLabel
										)}
										onClick={() => {
											sendEventGAAndBackend('click_sidebar_p2p');
											router.push(DashboardRoute.P2PS);
										}}
										style={{ marginLeft: '26px' }}
									>
										<CalendarNumberOutline size={SizeIconItem} />
										<Label className="ml-2">{t('nav.p2p')}</Label>
									</div>
									<div
										className={classnames(
											s.item,
											router.pathname == DashboardRoute.WEBINARS &&
												s.activeLabel
										)}
										onClick={() => {
											sendEventGAAndBackend('click_sidebar_webinar');
											router.push(DashboardRoute.WEBINARS);
										}}
										style={{ marginLeft: '26px' }}
									>
										<CalendarOutline size={SizeIconItem} />
										<Label className="ml-2">{t('nav.webinar')}</Label>
									</div>
									<div
										className={classnames(
											s.item,
											router.pathname == DashboardRoute.NOTIFICATIONS &&
												s.activeLabel
										)}
										onClick={() => {
											sendEventGAAndBackend('click_sidebar_notification');
											router.push(DashboardRoute.NOTIFICATIONS);
										}}
										style={{ marginLeft: '26px' }}
									>
										<BellOutline size={SizeIconItem} />
										<Label className="ml-2">{t('nav.notification')}</Label>
									</div>
									{session.user?.expert && (
										<>
											<div
												className={classnames(
													s.item,
													router.pathname ==
														DashboardRoute.SCHEDULE_SETTINGS &&
														s.activeLabel
												)}
												style={{ marginLeft: '26px' }}
												onClick={() => {
													sendEventGAAndBackend('click_sidebar_schedule');
													router.push(DashboardRoute.SCHEDULE_SETTINGS);
												}}
											>
												<Calendar1Outline size={SizeIconItem} />
												<Label className="ml-2">
													{t('nav.scheduleSettings')}
												</Label>
											</div>
										</>
									)}

									<div
										className={classnames(
											s.item,
											router.pathname == DashboardRoute.PAY_OUT &&
												s.activeLabel
										)}
										style={{ marginLeft: '26px' }}
										onClick={() => router.push(DashboardRoute.PAY_OUT)}
									>
										<Wallet2Outline size={SizeIconItem} />
										<Label className="ml-2">{t('nav.payout')}</Label>
									</div>

									<div
										className={classnames(
											s.item,
											router.pathname == DashboardRoute.ACCOUNT_SETTINGS &&
												s.activeLabel
										)}
										onClick={() => {
											sendEventGAAndBackend('click_sidebar_account');
											router.push(DashboardRoute.ACCOUNT_SETTINGS);
										}}
										style={{ marginLeft: '26px' }}
									>
										<UserOutline size={SizeIconItem} />
										<Label className="ml-2">{t('nav.accountSettings')}</Label>
									</div>
									<div
										className={s.item}
										style={{ marginLeft: '26px' }}
										onClick={() => logOut()}
									>
										<ExitLogoutOutline size={SizeIconItem} />
										<Label className="ml-2">{t('field.logout')}</Label>
									</div>
								</div>
							</>
						)}

						{/* Language */}
						<div className="ml-5 flex items-center">
							<GlobeOutlined size={SizeIconItem} />
							<Label className="ml-2">Language</Label>
						</div>

						{locales.map((locale, index) => (
							<div
								key={`locale-${index}`}
								className={classnames(
									s.item,
									'ml-11 mr-5 mt-2 justify-between',
									cookies.NEXT_LOCALE == locale && s.activeLabel
								)}
								onClick={() => toggleLanguage(locale)}
							>
								<Label>
									{locale == 'en'
										? 'English'
										: locale == 'vi'
										? 'Tiếng Việt'
										: ''}
								</Label>
								<div
									className={classnames(
										cookies.NEXT_LOCALE != locale && 'hidden'
									)}
								>
									<CheckmarkOutline size={20} />
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};
