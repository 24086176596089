import React, { useCallback, useMemo, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { BellOutline, CheckmarkOutline, TimeClockOutline } from '@pixiepkg/icon';
import { Body3, Button, H6, KindButton, Label3, Label4 } from '@pixiepkg/react';
import s from './Notifications.module.scss';
import ReactHtmlParser from 'react-html-parser';
import markDownStyles from '@styles/markDownStyles.module.scss';
import macScrollbarStyles from '@styles/macScrollbar.module.css';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { timeSince } from '@shared/utils/date';
import { useTranslation } from 'next-i18next';
import { seenAllNotificationsAtom, seenNotificationAtom } from '@shared/stores/notifications.store';
import { useAtom } from 'jotai';
import { BeatLoader } from 'react-spinners';
import { IconWrapper } from '@components/IconWrapper';
import { toHtml } from './utils';

interface IProps {
	isDark: boolean;
}
export const Notifications: React.FC<IProps> = ({ isDark }) => {
	const { t } = useTranslation('common');
	const [notis, seenNotification] = useAtom(seenNotificationAtom);
	const [, seenAllNotification] = useAtom(seenAllNotificationsAtom);
	const [loading, setLoading] = useState(false);
	const router = useRouter();
	const locale = router.locale === 'en' ? 'en' : 'vn'; // locale default is vi, but backend content.vn
	const newNotis = notis.filter((noti) => noti.status === 'new');
	const seenNotis = notis.filter((noti) => noti.status === 'seen');

	const onClickSeenAllNotis = useCallback(() => {
		setLoading(true);
		seenAllNotification().finally(() => setLoading(false));
	}, [seenAllNotification]);

	const onClickNotification = useCallback(
		(id: string, url: string) => {
			seenNotification(id).finally(() => {
				window.location.href = url;
			});
		},
		[seenNotification]
	);

	const renderSeeAll = useMemo(() => {
		if (newNotis?.length > 0) {
			if (loading) {
				return <BeatLoader size={8} />;
			}

			return (
				<button
					className="text-xs text-brand-500 underline"
					onClick={() => onClickSeenAllNotis()}
				>
					{t('field.seeAll')}
				</button>
			);
		}

		return <></>;
	}, [loading, newNotis?.length, onClickSeenAllNotis, t]);

	const renderIcon = useCallback((type: string) => {
		if (type === 'webpull_booking') {
			return (
				<IconWrapper color="#68D391">
					<CheckmarkOutline size={24} />
				</IconWrapper>
			);
		}

		if (type === 'webpull_rescheduled') {
			return (
				<IconWrapper color="#68D391">
					<BellOutline size={24} />
				</IconWrapper>
			);
		}

		if (type === 'webpull_reminder') {
			return (
				<IconWrapper color="#75C8FF">
					<TimeClockOutline size={24} />
				</IconWrapper>
			);
		}

		return null;
	}, []);

	return (
		<Menu as="div" className="relative inline-block text-left">
			<Menu.Button
				className={classNames(
					'relative flex cursor-pointer items-center rounded-lg border-none bg-transparent p-3',
					isDark ? 'hover:bg-black' : 'hover:bg-gray-100 active:bg-gray-100'
				)}
			>
				<BellOutline size={20} color={isDark ? '#fff' : ''} />
				{newNotis.length > 0 && (
					<div className="absolute right-3 top-3 h-2 w-2 rounded-full bg-red" />
				)}
			</Menu.Button>
			<Transition
				as={React.Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items
					className="focus:outline-none absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-lg bg-white p-2 shadow-level-4"
					style={{ zIndex: 999 }}
				>
					<div className="flex justify-between p-2">
						<H6 semiBold>{t('labels.noti')}</H6>
						{renderSeeAll}
					</div>
					<div
						className={classNames('overflow-y-scroll', macScrollbarStyles.macScrollbar)}
						style={{
							width: 387,
							maxHeight: '70vh'
						}}
					>
						{notis.length <= 0 && (
							<div className="mt-4 flex flex-col items-center justify-center">
								<BellOutline color="#3033d9" size={40} />
								<Body3>{t('labels.noNoti')}</Body3>
							</div>
						)}
						{newNotis.length > 0 && (
							<Label3 className="ml-2 mt-2 text-gray-700" semiBold>
								{t('field.new')}
							</Label3>
						)}
						{newNotis.map((noti) => {
							const content = noti.content[locale];
							if (!content) return null;
							const title = toHtml(content.title);
							const body = toHtml(content.body);
							return (
								<Menu.Item key={noti.id}>
									{() => (
										<Button
											kind={KindButton.GHOST}
											className={classNames(s.contentButton, 'bg-gray-50')}
											onClick={() =>
												onClickNotification(noti.id, content.url)
											}
										>
											<div className="flex">
												<div className="pt-1">
													{renderIcon(content.topic)}
												</div>
												<div
													className={classNames(
														'ml-4 flex-1',
														markDownStyles.content
													)}
												>
													<div
														className={classNames(
															'text-left',
															markDownStyles.title
														)}
													>
														{ReactHtmlParser(title)}
													</div>

													<div
														className={classNames(
															'text-left text-sm',
															markDownStyles.body
														)}
													>
														{ReactHtmlParser(body)}
													</div>

													<Label4 className="mt-2 text-left text-gray-400">{`${timeSince(
														noti.createdAt
													)} ago`}</Label4>
												</div>
											</div>
										</Button>
									)}
								</Menu.Item>
							);
						})}

						{seenNotis.length > 0 && (
							<Label3 className="ml-2 mt-2 text-gray-500" semiBold>
								{t('field.earlier')}
							</Label3>
						)}
						{seenNotis.map((noti) => {
							const content = noti.content[locale];
							if (!content) return null;
							const title = toHtml(content.title);
							const body = toHtml(content.body);

							return (
								<Menu.Item key={noti.id}>
									{() => (
										<Button
											kind={KindButton.GHOST}
											className={s.contentButton}
											onClick={() =>
												onClickNotification(noti.id, content.url)
											}
										>
											<div className="flex">
												<div className="pt-1">
													{renderIcon(content.topic)}
												</div>
												<div
													className={classNames(
														'ml-4',
														markDownStyles.content
													)}
												>
													<div
														className={classNames(
															'text-left',
															markDownStyles.title
														)}
													>
														{ReactHtmlParser(title)}
													</div>

													<div
														className={classNames(
															'text-left text-sm',
															markDownStyles.body
														)}
													>
														{ReactHtmlParser(body)}
													</div>

													<Label4 className="mt-2 text-left text-gray-400">{`${timeSince(
														noti.createdAt
													)} ago`}</Label4>
												</div>
											</div>
										</Button>
									)}
								</Menu.Item>
							);
						})}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
};
