export const getFullName = (firstName?: string, lastName?: string) => {
	const result = [];

	if (lastName) {
		result.push(lastName);
	}
	if (firstName) {
		result.push(firstName);
	}

	return result.join(' ');
};
