import React from 'react';
import { api } from '@api';
import { Menu, Transition } from '@headlessui/react';
import { Calendar01Outline, UserOutline, ExitLogoutOutline } from '@pixiepkg/icon';
import { Button } from '@pixiepkg/react';
import { loginModal } from '@shared/stores/loginModal.store';
import { sessionAtom } from '@shared/stores/session.store';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import s from './AuthAvatar.module.scss';
import { Star2Solid } from '@components/Icon';
import { getFullName } from '@shared/utils/name';
import { getTooltipProps } from '@shared/constant/tooltip';
import { useSendEvent } from '@shared/hooks/useSendEvent';

export const AuthAvatar: React.FC = () => {
	const router = useRouter();
	const [openLoginModal, setOpenLoginModal] = useAtom(loginModal);
	const [session, setSession] = useAtom(sessionAtom);
	const { t } = useTranslation();
	const { sendEventGAAndBackend } = useSendEvent();

	const logOut = () => {
		sendEventGAAndBackend('click_header_logout');
		localStorage.removeItem('ACCESS_TOKEN');
		localStorage.removeItem('PROFILE_UUID');

		setSession({ isFetched: true, isSignedIn: false });
		api.removeAuthHeader();
		toast.success('Log out successful!');
	};

	if (!session?.isFetched) {
		return <BeatLoader size={8} />;
	}

	if (session?.isSignedIn && session?.user) {
		return (
			<Menu as="div" className="relative inline-block text-left">
				<Menu.Button
					className="flex cursor-pointer items-center border-none bg-transparent"
					data-for={'app-tool-tip-avatar'}
					data-tip
				>
					<div className="relative rounded-full border border-solid border-white shadow-level-3 hover:shadow-level-4">
						<img
							src={session?.user?.avatar || 'https://dummyimage.com/40x40'}
							alt="avatar"
							className="h-10 w-10 rounded-full object-cover"
						/>
						{session?.user?.expert && (
							<div
								className="absolute -bottom-0.5 -left-0.5 flex items-center justify-center rounded-full bg-black"
								style={{ width: '18px', height: '18px' }}
								{...getTooltipProps(
									`Expert: ${getFullName(
										session?.user?.firstName,
										session?.user?.lastName
									)}`
								)}
							>
								<Star2Solid size={12} />
							</div>
						)}
					</div>
				</Menu.Button>
				<Transition
					as={React.Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="focus:outline-none absolute right-0 z-50 mt-2 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-lg bg-white shadow-level-3 ring-1 ring-black ring-opacity-5">
						<Menu.Item>
							{({ active }) => (
								<button
									style={{ borderRadius: 0 }}
									className={classnames(active && 'bg-gray-100', s.button)}
									onClick={() => {
										sendEventGAAndBackend('click_header_dashboard');
										router.push('/dashboard');
									}}
								>
									<Calendar01Outline size={16} />
									<p className={s.buttonText}>{t('field.dashboard')}</p>
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									style={{ borderRadius: 0 }}
									className={classnames(active && 'bg-gray-100', s.button)}
									onClick={() => {
										sendEventGAAndBackend('click_header_my_account');
										router.push('/dashboard/account-settings');
									}}
								>
									<UserOutline size={16} />
									<p className={s.buttonText}>{t('field.myAccount')}</p>
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									style={{ borderRadius: 0 }}
									className={classnames(active && 'bg-gray-100', s.button)}
									onClick={() => {
										sendEventGAAndBackend('click_header_become_expert');
										router.push('/become-an-expert');
									}}
								>
									<p className={s.buttonText}>{t('nav.expert')}</p>
								</button>
							)}
						</Menu.Item>
						{session?.user?.expert && (
							<Menu.Item>
								{({ active }) => (
									<button
										style={{ borderRadius: 0 }}
										className={classnames(active && 'bg-gray-100', s.button)}
										onClick={() => {
											sendEventGAAndBackend('click_header_host_event');
											router.push('/host-an-event');
										}}
									>
										<p className={s.buttonText}>{t('nav.host')}</p>
									</button>
								)}
							</Menu.Item>
						)}
						<Menu.Item>
							{({ active }) => (
								<button
									style={{ borderRadius: 0 }}
									className={classnames(active && 'bg-gray-100', s.button)}
									onClick={logOut}
								>
									<ExitLogoutOutline size={16} />
									<p className={s.buttonText}>{t('field.logout')}</p>
								</button>
							)}
						</Menu.Item>
					</Menu.Items>
				</Transition>
			</Menu>
		);
	}

	return (
		<Button
			buttonProps={{
				id: 'p-login-button'
			}}
			className="px-8"
			rounded
			onClick={() => {
				setOpenLoginModal({
					isOpen: !openLoginModal.isOpen,
					from: 'login_button'
				});
			}}
		>
			{t('field.login').toUpperCase()}
		</Button>
	);
};
