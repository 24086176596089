export const navMainItems = [
	{
		name: 'Home',
		href: '/',
		active: false,
		eventName: ''
	},
	{
		name: 'Blogs',
		href: '/blogs',
		active: false,
		eventName: 'click_header_blog'
	},
	{
		name: 'About Pixie',
		href: '/contact',
		active: false,
		eventName: 'click_header_about_pixie'
	},
	{
		name: 'Đăng ký trở thành chuyên gia',
		href: '/become-an-expert',
		active: false,
		eventName: 'click_header_become_expert'
	},
	{
		name: 'Đăng ký mở webinar',
		href: '/host-an-event',
		active: false,
		eventName: 'click_header_host_event'
	}
];
