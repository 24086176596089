import React, { useCallback, useEffect } from 'react';
import { NavigationBar } from '@components/Navigations/NavigationBar';
import classnames from 'classnames';
import { NavigationBarMobile } from '@components/Navigations/NavigationBarMobile';
import s from '@components/Template/styles/template.module.scss';
import { NavigationDashboard } from '@components/Navigations/NavigationDashboard';
import { Footer } from '@components/Footer';
import { refreshNotificationsAtom } from '@shared/stores/notifications.store';
import { sessionAtom } from '@shared/stores/session.store';
import { useAtom } from 'jotai';
import { SCREEN_SIZES, useWindowSize } from '@shared/hooks/useWindownSize';

interface Props {
	children?: React.ReactNode;
	fixedNavBar?: boolean;
	showDashboard?: boolean;
	showFooter?: boolean;
	showNavigationLinks?: boolean;
	title?: string;
	transparentEffect?: boolean;
	showSearchAfterHeight?: number;
	isDark?: boolean;
}

export const Template = ({
	children,
	fixedNavBar,
	showDashboard,
	showFooter,
	showNavigationLinks,
	title,
	transparentEffect,
	isDark = false
}: // showSearchAfterHeight
Props) => {
	const [session] = useAtom(sessionAtom);
	const [, refreshNoti] = useAtom(refreshNotificationsAtom);
	const { width } = useWindowSize();

	const fetchNotis = useCallback(() => {
		refreshNoti();
	}, [refreshNoti]);

	useEffect(() => {
		let fetchNotiInterval: NodeJS.Timeout | null = null;
		if (session?.accessToken && session.isFetched && session.isSignedIn) {
			fetchNotis();
			if (fetchNotiInterval) {
				clearInterval(fetchNotiInterval);
			}
			fetchNotiInterval = setInterval(fetchNotis, 60000);
		}
		return () => {
			if (fetchNotiInterval) {
				clearInterval(fetchNotiInterval);
			}
		};
	}, [fetchNotis, session]);

	return (
		<>
			{/*<ScrollToTop />*/}
			<div
				className={classnames('flex', showDashboard && 'w-screen')}
				suppressHydrationWarning
			>
				<div className={classnames(s.navDashboard, 'md:hidden')}>
					{showDashboard && <NavigationDashboard />}
				</div>
				<div className="flex w-full flex-col">
					{width > SCREEN_SIZES.xs || width === 0 ? (
						<div className={s.navDesktop}>
							<NavigationBar
								isDark={isDark}
								fixed={fixedNavBar}
								showNavigationLinks={showNavigationLinks}
								showLogo={!showDashboard}
								title={title}
							/>
						</div>
					) : (
						<div className={s.navMobile}>
							<NavigationBarMobile isDark={isDark} />
						</div>
					)}

					<main
						id="main-content"
						className={classnames(
							'max-h-full flex-1 md:pt-16',
							fixedNavBar && !transparentEffect && s.pushDownForNav
						)}
					>
						{children}
					</main>
				</div>
			</div>
			{showFooter && <Footer isDark={isDark} />}
		</>
	);
};
