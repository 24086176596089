import React from 'react';
import Link from 'next/link';
import styles from '@components/Footer/Footer.module.scss';
import { Body2, Body3, Label2 } from '@pixiepkg/react';
import { MailOutline, PhoneOutline, PixieSolid } from '@pixiepkg/icon';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { PixieSolidDark } from '@components/Icon';

interface IProps {
	isDark?: boolean;
}
export const Footer: React.FC<IProps> = ({ isDark }) => {
	const { t } = useTranslation('common');

	return (
		<footer id="pixie-footer" className={isDark ? 'w-full bg-[#1C1E23]' : 'w-full bg-gray-50'}>
			<div className={classNames(styles.container, styles.row)}>
				{/* left */}
				{/* section 1 */}
				<div className={classNames(styles.col, '-mt-8 max-w-[300px]')}>
					{isDark ? <PixieSolidDark size={120} /> : <PixieSolid size={120} />}
					<Body3 medium className="-mt-6 text-gray-500">
						©️ 2022 Pixie - All Rights Reserved.
					</Body3>
					<a
						href="http://online.gov.vn/Home/WebDetails/90237"
						target="_blank"
						rel="noreferrer"
					>
						<img alt="btc" title="btc" className="pr-16" src="/btc.svg" />
					</a>
				</div>

				{/* right */}
				<div className={classNames(styles.col)}>
					<div className={classNames(styles.row, 'xs:flex-col')}>
						{/* section 2 */}
						<div className={classNames('flex-2 flex flex-col', styles.col)}>
							<Label2 className={isDark ? 'text-white' : undefined}>
								{t('footer.name')}
							</Label2>
							<div style={{ maxWidth: '555px' }} className="mt-3">
								<Body2 className={isDark ? 'text-gray-500' : undefined}>
									{t('footer.address')}
								</Body2>
							</div>
							<div style={{ maxWidth: '555px' }} className="mt-3">
								<Body2 className={isDark ? 'text-gray-500' : undefined}>
									Số đăng ký kinh doanh: 0316730612, ngày 04 tháng 03 năm 2021 do
									Sở Kế hoạch đầu tư Thành phố Hồ Chí Minh cấp.
								</Body2>
							</div>
						</div>

						{/* section 3 */}
						<div
							className={classNames('flex min-w-[300px] flex-1 flex-col', styles.col)}
						>
							<Label2 className={isDark ? 'text-white' : undefined}>Contact</Label2>
							<div className="flex">
								<PhoneOutline color={isDark ? '#fff' : undefined} size={20} />
								<Body3 className="ml-3 font-medium text-brand-500">
									(+84) 357 822 351
								</Body3>
							</div>
							<div className="flex">
								<MailOutline color={isDark ? '#fff' : undefined} size={20} />
								<Body3 className="ml-3 cursor-pointer font-medium text-brand-500">
									hi@gotpixie.com
								</Body3>
							</div>
						</div>
					</div>
					{/* Bottom Links */}
					<div className={classNames(styles.listLinks)}>
						<Link className={isDark ? 'text-white' : undefined} href="/contact">
							{t('footer.contact')}
						</Link>
						<Link href="https://www.facebook.com/gotpixie" legacyBehavior>
							<a className={isDark ? 'text-white' : undefined} target="_blank">
								Facebook
							</a>
						</Link>
						<Link className={isDark ? 'text-white' : undefined} href="/about">
							{t('footer.about')}
						</Link>
						<Link className={isDark ? 'text-white' : undefined} href="/how-it-works">
							{t('footer.works')}
						</Link>
						<Link className={isDark ? 'text-white' : undefined} href="/privacy-policy">
							{t('footer.privacy')}
						</Link>
						<Link
							className={isDark ? 'text-white' : undefined}
							href="/terms-of-service"
						>
							{t('footer.terms')}
						</Link>
					</div>
				</div>
			</div>
		</footer>
	);
};
