/* eslint-disable @next/next/no-img-element */
import React, { useCallback, useEffect, useState } from 'react';
import s from '@components/Navigations/NavigationBar/styles/nav.module.scss';
import { Button, H4, KindButton, Label2, Textbox } from '@pixiepkg/react';
import { useRouter } from 'next/router';
import { BlogSolid, GroupPeopleSolid, PixieSolid2, PixieSolidDark } from '@components/Icon';
import classnames from 'classnames';
import Link from 'next/link';
import { sessionAtom } from '@shared/stores/session.store';
import { useAtom } from 'jotai';
import { CalendarOutline, SearchOutline } from '@pixiepkg/icon';
import { useTranslation } from 'next-i18next';
import { AuthAvatar } from './AuthAvatar';
import { ChangeLanguages } from './ChangeLanguages';
import { Notifications } from './Notifications';
import { Transition } from '@headlessui/react';
import { useSendEvent } from '@shared/hooks/useSendEvent';
import SvgExpertSolid from '@components/Icon/components/ExpertSolid';

interface Props {
	fixed?: boolean;
	showLogo?: boolean;
	showNavigationLinks?: boolean;
	title?: string;
	transparentEffect?: boolean;
	showSearchAfterHeight?: number;
	isDark: boolean;
}

export const NavigationBar = ({
	fixed,
	showLogo,
	showNavigationLinks,
	title,
	transparentEffect,
	showSearchAfterHeight,
	isDark
}: Props) => {
	const router = useRouter();
	const [session] = useAtom(sessionAtom);
	const { t } = useTranslation();
	const [searchStr, setSearchStr] = useState((router.query?.keyword as string) || '');
	const [background, setBackground] = useState(transparentEffect ? 'transparent' : 'white');
	const [showSearch, setShowSearch] = useState(false);
	const { sendEventGAAndBackend } = useSendEvent();

	const getNavLinksClassnames = useCallback(
		(link: string) => {
			const _classes = [];
			_classes.push('linkHover');
			if (link === router.asPath) {
				_classes.push(s.linkActive);
			}

			return _classes.join(' ');
		},
		[router.asPath]
	);

	const NavItem = ({ title = '', link = '', Icon, isDark, onClick = () => {} }: any) => {
		const active = link === router.asPath;
		return (
			<Link href={link} legacyBehavior>
				<a
					style={{ textDecoration: 'none' }}
					className={classnames(getNavLinksClassnames(link), 'flex items-center')}
					onClick={onClick}
				>
					<div className="flex h-full items-center justify-center">
						{Icon && (
							<div className="mr-2">
								<Icon
									size={24}
									color={active ? '#444cff' : isDark ? '#CBD5E0' : undefined}
								/>
							</div>
						)}
					</div>
					<Label2 color={active ? '#444cff' : isDark ? '#CBD5E0' : 'currentColor'}>
						{title}
					</Label2>
				</a>
			</Link>
		);
	};

	const onSearch = (str: string) => {
		router.push({
			pathname: '/experts',
			query: { ...router.query, query: str }
		});
	};

	useEffect(() => {
		const changeBackground = () => {
			const _height = 40;
			if (window.scrollY <= _height && background !== 'transparent') {
				setBackground('transparent');
			} else if (window.scrollY > _height && background !== 'white') {
				setBackground('white');
			}

			if (showSearchAfterHeight && window.scrollY > showSearchAfterHeight) {
				setShowSearch(true);
			} else if (showSearchAfterHeight && window.scrollY <= showSearchAfterHeight) {
				setShowSearch(false);
			}
		};
		if (transparentEffect && window) {
			window.addEventListener('scroll', changeBackground);
		}

		return () => {
			window.removeEventListener('scroll', changeBackground);
		};
	}, [transparentEffect, background, showSearchAfterHeight]);

	return (
		<nav
			className={classnames(
				s.nav,
				fixed && s.fixed,
				'transform transition-all duration-300 ease-in-out'
			)}
			style={{
				background: isDark ? '#1C1E23' : background,
				boxShadow: isDark
					? '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
					: background === 'white' && !router.pathname.includes('dashboard')
					? '-1px -1px 4px rgba(51, 60, 68, 0.05), 0px 4px 12px -4px rgba(51, 60, 68, 0.12)'
					: ''
			}}
			id="pixie-nav"
		>
			<div className={s.container}>
				{/* Logo pixie */}
				{showLogo && (
					<Link legacyBehavior href={'/'}>
						<a className="flex items-end">
							{isDark ? <PixieSolidDark size={80} /> : <PixieSolid2 size={80} />}
						</a>
					</Link>
				)}

				{/* Header */}
				{title && <H4 className={s.title}>{title}</H4>}

				{/* Navigation links */}
				{showNavigationLinks && (
					<div className="ml-20 flex flex-1 items-center space-x-6">
						{session.user?.expert ? (
							<div className="lg:hidden">
								<NavItem
									isDark={isDark}
									title="My Profile"
									link={`/experts/${session.user?.id}`}
									Icon={SvgExpertSolid}
									onClick={() => {
										sendEventGAAndBackend('click_header_my_account');
									}}
								/>
							</div>
						) : (
							<div className="lg:hidden">
								<NavItem
									isDark={isDark}
									title={t('nav.expert')}
									link="/become-an-expert"
									Icon={SvgExpertSolid}
									onClick={() => {
										sendEventGAAndBackend('click_header_become_expert');
									}}
								/>
							</div>
						)}
						<NavItem
							isDark={isDark}
							title={t('nav.blogs')}
							link="/blogs"
							Icon={BlogSolid}
							onClick={() => {
								sendEventGAAndBackend('click_header_blog');
							}}
						/>
						<NavItem
							isDark={isDark}
							title={t('nav.about')}
							link="/about"
							Icon={GroupPeopleSolid}
							onClick={() => {
								sendEventGAAndBackend('click_header_about_pixie');
							}}
						/>
						<div className="h-6 w-4 border-l border-solid border-gray-200"></div>
						<Transition
							enter="transition ease-in duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition ease-in duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
							show={!showSearchAfterHeight || (!!showSearchAfterHeight && showSearch)}
						>
							<div className={classnames('w-80', s.searchBar)}>
								<Textbox
									id="p-search-input"
									onClick={() => {
										sendEventGAAndBackend('click_home_search_bar');
									}}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											onSearch(searchStr);
											sendEventGAAndBackend('click_home_search_now', {
												search_content: searchStr
											});
										}
									}}
									value={searchStr}
									onChange={(e) => setSearchStr(e.currentTarget.value)}
									iconLeft={<SearchOutline size={18} />}
									placeholder="Search"
								/>
							</div>
						</Transition>
					</div>
				)}

				<div className="ml-4 flex items-center">
					{session.isSignedIn && (
						<Button
							leftIcon={<CalendarOutline size={18} />}
							kind={KindButton.BRAND}
							className="mr-4"
							onClick={() => {
								router.push('/dashboard/p2ps?tab=upcoming');
							}}
						>
							{t('nav.bookings')}
						</Button>
					)}

					{session.isSignedIn && <Notifications isDark={isDark} />}

					<ChangeLanguages isDark={isDark} />

					<AuthAvatar />
				</div>
			</div>
		</nav>
	);
};
