import { atom } from 'jotai';
import { Notification } from '@shared/models/Notification.model';
import { api } from '@api';
import { toast } from 'react-toastify';

const defaultNotis: Notification[] = [];

export const notificationsAtom = atom(defaultNotis);

// ==== ACTIONS ====

// Seen one action
export const seenNotificationAtom = atom(
	(get) => get(notificationsAtom),
	async (_get, set, id: string) => {
		const notis = _get(notificationsAtom);
		try {
			await api.seenMyNotifications(id);
			set(notificationsAtom, [
				...notis.map((noti) => {
					if (noti.id === id) {
						return {
							...noti,
							status: 'seen'
						};
					}
					return noti;
				})
			]);
		} catch (error) {
			toast.error('Can not seen notification!');
		}
	}
);

// Seen all action
export const seenAllNotificationsAtom = atom(
	(get) => get(notificationsAtom),
	async (_get, set) => {
		const notis = _get(notificationsAtom);
		try {
			await api.seenAllMyNotifications();
			set(notificationsAtom, [
				...notis.map((noti) => {
					return {
						...noti,
						status: 'seen'
					};
				})
			]);
		} catch (error) {
			toast.error('Can not seen all notifications!');
		}
	}
);

// Fetch new noti action
export const refreshNotificationsAtom = atom(
	(get) => get(notificationsAtom),
	async (_get, set) => {
		api.getMyNotifications()
			.then((res) => {
				set(notificationsAtom, res.data || []);
			})
			.catch(() => {
				// toast.error('Can not get notifications!');
			});
	}
);
