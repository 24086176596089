import dayjs from 'dayjs';

// Apr, 2021
export const getMonthYear = (date?: number | 0) => {
	//date: ISO string
	if (!date) {
		return '';
	}

	return dayjs.unix(date).format('MMM YYYY');
};

// 01 Apr, 2021
export const getFullDate = (date?: string | Date, locale = 'vi') => {
	//date: ISO string
	if (!date || new Date(date).toString() === 'Invalid Date') {
		return '';
	}

	const intl = new Intl.DateTimeFormat(locale, {
		year: 'numeric',
		month: 'long',
		day: '2-digit',
		weekday: 'short'
	});

	return intl.format(new Date(date));
};

// 01 Apr, 2021
export const getShortDate = (date?: string | Date, locale = 'vi') => {
	//date: ISO string
	if (!date || new Date(date).toString() === 'Invalid Date') {
		return '';
	}

	const intl = new Intl.DateTimeFormat(locale, {
		month: 'long',
		day: '2-digit'
	});

	return intl.format(new Date(date));
};

export const getTime = (date?: string | Date) => {
	//date: ISO string
	if (!date) {
		return '';
	}

	const intl = new Intl.DateTimeFormat('en-GB', {
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit'
	});

	return intl.format(new Date(date));
};

// 01 Apr, 2021
export const getShortDateNumeric = (date?: string | Date, locale = 'vi') => {
	//date: ISO string
	if (!date || new Date(date).toString() === 'Invalid Date') {
		return '';
	}

	const intl = new Intl.DateTimeFormat(locale, {
		month: 'numeric',
		day: 'numeric'
	});

	return intl.format(new Date(date));
};

/**
 *
 * @param start unit
 * @param end  unit
 * @returns if minutes is less than 60, return minutes else return hours and minutes
 */
export const getTimeRange = (start?: number, end?: number) => {
	if (!start || !end) {
		return '';
	}

	const startTime = dayjs.unix(start).format('HH:mm');
	const endTime = dayjs.unix(end).format('HH:mm');

	return `${startTime} - ${endTime}`;
};

export const getTimeRangeStartDuration = (start?: number, durationSec?: number) => {
	if (!start || !durationSec) {
		return '';
	}

	const startTime = dayjs.unix(start).format('HH:mm');
	const endTime = dayjs.unix(start).add(durationSec, 'seconds').format('HH:mm');

	return `${startTime} - ${endTime}`;
};

export function timeSince(unixTime?: number) {
	if (!unixTime) {
		return '';
	}

	const date = dayjs.unix(unixTime).toDate();
	const curDate = new Date();

	const seconds = Math.floor((curDate.getTime() - date.getTime()) / 1000);

	let interval = seconds / 31536000;

	if (interval > 1) {
		return Math.floor(interval) + ' years';
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) + ' months';
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + ' days';
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + ' hours';
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + ' minutes';
	}
	return Math.floor(seconds) + ' seconds';
}

export const unixToInputDateStr = (unixDate: number) => {
	if (!unixDate) {
		return '';
	}
	return dayjs.unix(unixDate).format('YYYY-MM-DD');
};

export const inputDateStrToUnix = (inputDateStr: string) => {
	// YYYY-MM-DD
	if (!inputDateStr) {
		return '';
	}

	return dayjs(inputDateStr).unix();
};

export const magicDate = (listDate: string[]) => {
	// const listDemo = ['23/10', '24/10', '25/10', '28/10', '1/11'];
	const date: any = {};
	const result: string[] = [];
	listDate.forEach((item) => {
		const itemSplit = item.split('/');
		const key = itemSplit[1];
		const value = itemSplit[0] as any;
		if (!date[key]) date[key] = [];
		date[key] = [].concat(date[key], value);
	});

	Object.keys(date).forEach((item) => {
		date[item].sort((a: string, b: string) => {
			return Number(a) - Number(b);
		});
		let temp = '';
		let memoryNumber: any = 0;
		while (date[item].length > 0) {
			const value = date[item].shift();

			if (temp.length === 0) {
				temp = temp.concat(value);
			} else {
				if (value - memoryNumber === 1 && date[item][0] - value !== 1) {
					temp = temp.concat('-', value);
				} else if (value - memoryNumber !== 1) {
					temp = temp.concat(', ', value);
				}
			}

			if (date[item].length === 0) temp = temp.concat('/', item);
			memoryNumber = value;
		}

		result.push(temp);
	});
	return result;
};
