export const toHtml = (str: string) => {
	str = convert(str, '**', '<strong>', '</strong>');
	str = convert(str, '*', '<em>', '</em>');
	return str;
};
const convert = (str: string, char: string, open: string, close: string) => {
	const arr = [];
	let hasChange = false;
	let start = 0;
	let end;
	while (str.indexOf(char, start) != -1) {
		hasChange = true;
		end = str.indexOf(char, start);
		const str1 = str.slice(start, end);
		arr.push(str1);
		arr.push(char);
		start = end + char.length;
		// end of string
		if (str.indexOf(char, start) == -1) {
			const str2 = str.slice(start, str.length);
			arr.push(str2);
		}
	}

	let temp = '';
	let isOpen = true;
	for (let index = 0; index < arr.length; index++) {
		if (arr[index] == char) {
			arr[index] = isOpen ? open : close;
			isOpen = !isOpen;
		}
		temp = temp + arr[index];
	}
	if (!hasChange) {
		return str;
	}
	return temp;
};
