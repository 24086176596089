import React, { useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import { sessionAtom } from '../stores/session.store';
import { api } from '@api';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import useMounted from '@shared/hooks/useMounted';
import { BeatLoader } from 'react-spinners';
import { getFullName } from '@shared/utils/name';
import { Profile } from '@shared/models/Profile.model';
import * as Sentry from '@sentry/browser';

type HOC = (Component: (props: any) => JSX.Element) => React.FC;

export interface WithSessionOptions {
	requireSession?: boolean;
	redirectTo?: string;
}

export function withSession(opts?: WithSessionOptions): HOC {
	const requireSession = opts?.requireSession ?? false;
	const redirectTo = opts?.redirectTo ?? '/';

	return (Component) => {
		return function ComponentWithSession(props) {
			const router = useRouter();
			const [session, setSession] = useAtom(sessionAtom);
			const mounted = useMounted();

			const updateProfile = useCallback(
				(token: string) => {
					const sendIDToTracker = (user: Profile) => {
						const { id, email, firstName, lastName } = user;
						const name = getFullName(firstName, lastName);
						(window as any)?.FS?.identify(id, {
							displayName: name,
							email: email
						});
						Sentry.setUser({ email, id, username: name });
					};
					api.setToken(token);
					(async () => {
						try {
							const user = await api
								.getAuthenticatedProfile()
								.then((res) => res.data);
							setSession({
								...session,
								isFetched: true,
								accessToken: token,
								isSignedIn: true,
								user: user
							});
							localStorage.setItem('ACCESS_TOKEN', token);
							sendIDToTracker(user);
						} catch (e) {
							setSession({
								...session,
								isFetched: true,
								isSignedIn: false
							});
							localStorage.removeItem('ACCESS_TOKEN');
							api.removeAuthHeader();
							toast.error('Can not get user profile, please login again!');
						}
					})();
				},
				[session, setSession]
			);

			// fetching profile
			useEffect(() => {
				if (router.isReady) {
					const tokenQuery = router.query.token;
					if (!session.isFetched && session.accessToken) {
						updateProfile(session.accessToken);
					}

					if (!session.isFetched && !session.accessToken) {
						if (tokenQuery && typeof tokenQuery === 'string') {
							api.setToken(tokenQuery);
							updateProfile(tokenQuery);
						} else {
							setSession({
								...session,
								isFetched: true,
								isSignedIn: false
							});
							if (requireSession) {
								router.push(redirectTo);
								toast.error('You need to login first!');
							}
						}
					}
				}
			}, [session, setSession, router, updateProfile]);

			if ((requireSession && !mounted) || (requireSession && !session.isFetched)) {
				return <BeatLoader />;
			}

			return <Component {...props} />;
		};
	};
}
